export function getLocalData() {
  // Get the path of the json file
  const data = getData();

  return data;
}

export function getAllProjects() {
  const data = getData()
  return data.projects;
}

export function getProject(slug) {
  // Get the path of the json file
  const data = getData();
  // Parse data as json

  const project = data.projects.find((item) => {
    if (item.slug == slug) {
      return item

    }
  })
  return project
}

export function getBairros() {
  const bairros = [
    {
      bairro: "Leblon", desc_curta: "Um lugar só seu no coração do Leblon", metragem: "Experiências de 135m² a 569m²", image: "./Home/Leblon.jpg",
      desc: "Sinônimo de exclusividade, elegância e prestígio o bairro já foi cenário de novelas, filmes e encanta pelas suas belezas naturais como o morro dois irmãos e a sua praia ideal para prática de esportes."
      // desc: "Celebrar a vida à beira-mar, a luz solar e o dom de ser feliz: seja bem-vindo ao Leblon. Aqui, os pés tocam a areia e a maresia gentilmente recorda que a vida corre no momento presente. Fazer morada no Leblon é enfim entender todas as milhares de poesias e canções escritas em homenagem ao Rio, e eleger a inspiração como rotina. Esteja perto de tudo e caminhe com fluidez entre a natureza, boemia e gastronomia, na máxima expressão da alma carioca traduzida da arquitetura às entrelinhas de cada esquina"
    },
    // {
    //   bairro: "Lagoa", desc_curta: "Seja bem-vindo ao tom perfeito para a sua vida.", metragem: "Experiências a partir de 138m²", image: "./Home/Lagoa.jpg",
    //   desc: "Considerada por muitos um dos cartões postais mais inesquecíveis do Rio, a Lagoa Rodrigo de Freitas atrai e encanta milhares de admiradores ao redor do mundo. Experimente viver  ao ar livre e ter a beleza natural da cidade como sua inspiração diária. E para os amantes da alta gastronomia, a Lagoa nos brinda com seu renomado polo gastronômico, num convite a desfrutar do melhor da vida carioca"
    // },
    {
      bairro: "Gávea", desc_curta: "Viva a experiência de uma vida mais leve e a sensação de estar de férias o ano inteiro", metragem: "Experiências a partir de 27m² a 179m²", image: "./Home/Gávea.jpg",
      desc: "Explore a (re)conexão entre cidade e natureza no Rio."
      // desc: "Na Gávea, a contemplação da alma natural carioca encontra aqui a fusão singular com o Parque Nacional da Tijuca, trazendo a (re)conexão entre cidade e floresta. É o convite a  ressignificar os sentidos, a rotina e a vida, transbordando inspiração na construção de memórias inesquecíveis. Viva a experiência única de estar cercado por uma paisagem verde de perder o fôlego e ainda assim apenas 10 minutos de distância da Praia do Leblon "
    },
    {
      bairro: "Jardim Botânico", desc_curta: "O local ideal para viver com comodidade e praticidade.", metragem: "Experiências de 41m² a 92m²", image: "./Home/JB.jpg",
      desc: "Sinta o verde das montanhas e deixe o clima agradável do bairro te inspirar."
      // desc: "Sinta o verde das montanhas e o clima agradável do bairro te envolverem e inspirarem. O Jardim Botânico, dotado da vocação natural de um refúgio urbano em pleno Rio de Janeiro, une sua beleza bucólica à localização estratégica, se tornando, assim, o endereço ideal para quem busca a tranquilidade sem abrir mão da comodidade na rotina"
    },
    {
      bairro: "Ipanema", desc_curta: "Mudando o significado da palavra rotina", metragem: "Experiências de 32m² a 297m²", image: "./Home/Ipanema.jpg",
      desc: "Viva a essência carioca na praia eleita a segunda melhor do mundo."
      // desc: "Entre a areia e o mar, Ipanema é o convite à essência solar carioca. O privilégio de caminhar entre as charmosas ruas do bairro é descobrir a beleza do Rio como quintal. Viva a rotina do amanhecer na praia e o entardecer no bar brindando à vida com amigos, ou explorando um novo restaurante internacional no efervescente polo gastronômico da região. Ipanema é a eterna e sorte de morar na cidade maravilhosa"
    },
    {
       bairro: "Botafogo", desc_curta: "Onde a arte e arquitetura se unem e inspiram a contemplação do Rio", metragem: "Experiências a partir de 104m²", image: "./Home/Botafogo.jpg",
       desc: <>Botafogo é onde cultura, história e gastronomia se encontram.</>
    }
  ]
  return (bairros)
}

function getData() {
  const data = {
    "projects": [
      {
        "sellpercent": 'OPORTUNIDADE',
        "slug": "verde",
        "image": "Verde/Verdê.jpg",
        "title": "Verdê",
        "search": "Jardim Botânico|Sala/quarto|De 70 a 100m2|Flora",
        "location": "Jardim Botânico",
        "size": "De 90 a 120m²",
        "rooms": "1 quarto",
        "tags": [
          "Última Unidade",
          "Cobertura | 1 quarto",
          "92m²",
          "Previsão de entrega: 2025"
        ],
        "project": {
          "id": 100,
          "hero": "Verde/Verdê.jpg",
          "title": "Verdê",
          "video": "https://www.youtube.com/embed/38zmCe_VXPk?si=y6yweXPYmHESS6zT",
          "video_image": "Verde/3.webp",
          "datasheet_image": "Verde/1.webp",
          "arch_image": "RAFarquitetura.jpg",
          "subtitle": "",
          "gallery": [
            "Verde/1.webp",
            "Verde/2.webp",
            "Verde/3.webp",
            "Verde/4.webp",
            "Verde/5.webp",
          ],
          "tags": [
            "Última Unidade",
            "Cobertura | 1 quarto",
            "92m²",
            "Previsão de entrega: 2025"
          ],
          "address": "Rua Jardim Botânico, 221, Jardim Botânico",
          "about": "Viver no Jardim Botânico é se conectar com a essência natural da cidade, caminhando em meio ao ar puro e tranquilidade. Caminhar por aqui é se inspirar com a beleza vida em movimento, ouvir o canto de pássaros pela manhã e recordar que faz parte da nossa natureza ser carioca.",
          "aboutProject": "Conheça nossa última unidade no Verdê e explore a sinergia entre a arquitetura viva e o respiro urbano no bairro mais charmoso e da Zona Sul. Um residencial de design exclusivo e inspirado na natureza, idealizado por profissionais renomados e assinado pela Mozak",
          "arch_title": "Projeto: RAF Arquitetura",
          "arch_text": "Fundada em 1989, RAF Arquitetura traduz no Verdê a harmonia entre o presente e passado, em meio à alma verde, solar e bucólica do Jardim Botânico: “Com o programa estabelecido, buscamos respeitar ao máximo a edificação valorizando seus principais elementos arquitetônicos escadas, esquadrias, telhados, ornatos. [...] Entre o casarão e o edifício, um pátio interno de convívio faz a transição entre o novo e histórico.”",
          "datasheet": [
            "Projeto de paisagismo: Luiz Carlos Orsini",
            "Design de Interiores: PKB Arquitetura",
            "Terraço privativo",
            "Bicicletário",
            "Soluções sustentáveis",
            "Sistema de tecnologia e segurança, com alarmes, sensores perimetrais e circuito CFTV"
          ]
        }
      },

      {
        "sellpercent": "OPORTUNIDADE",
        "slug": "jardins",
        "image": "Jardins/Capa.webp",
        "title": "Jardins",
        "search": "Jardim Botânico|Sala/quarto|De 70 a 100m2|Flora",
        "location": "Jardim Botânico",
        "size": "De 90 a 120m²",
        "rooms": "1 quarto",
        "tags": [
          "Última Cobertura",
          "3 quartos",
          "Área de lazer privativa",
          "Previsão de entrega: 2024"
        ],
        "project": {
          "id": 12,
          "hero": "Jardins/Capa.webp",
          "title": "Jardins",
          "video": "",
          "video_image": "flora_video.png",
          "datasheet_image": "Jardins/Galeria/Facha new.webp",
          "arch_image": "Jardins/Arquitetas.png",
          "subtitle": "",
          "gallery": [
            "Jardins/Galeria/04_VARANDA_APTO_202_REV02.webp",
            "Jardins/Galeria/08_SALA_201_PAV_SUP_REV01.webp",
            "Jardins/Galeria/09_TERRACO_APARTAMENTO_201_PAVIMENTO_SUPERIOR_REV02_02_HR.webp",
            "Jardins/Galeria/10_TERRACO_APARTAMENTO_202_PAVIMENTO_SUPERIOR_REV01_01_HR.webp",
            "Jardins/Galeria/18_FACHADA DIURNA_REV05-HR (1).webp",
            "Jardins/Galeria/19_FACHADA NOTURNA_REV06_HR.webp",
            "Jardins/Galeria/20_FACHADA_ACESSO_REV01.webp",
            "Jardins/Galeria/Facha new.webp",
            "Jardins/Galeria/portaria new.webp",
          ],
          "tags": [
            "Última Cobertura",
            "3 quartos",
            "Área de lazer privativa",
            "Previsão de entrega: 2024"
          ],
          "address": "Rua J. Carlos, 101, Jardim Botânico",
          "about": "Viver no Jardim Botânico é se conectar com a essência natural da cidade, caminhando em meio ao ar puro e tranquilidade. Caminhar por aqui e se inspirar com a beleza vida em movimento, ouvir o canto de pássaros pela manhã e recordar que faz parte da nossa natureza ser carioca.",
          "aboutProject": "Descubra o Jardins e a sensação de viver em harmonia com a natureza e cercado por um paisagismo acolhedor. Inspirada no conceito de casa, a arquitetura do residencial evoca uma sensação constante de que tudo é familiar e harmônico, em um ambiente cercado por vida.",
          "arch_title": "Escala Arquitetura | Interiores, Fachadas e Personalização",
          "arch_text": "O novo respeita o antigo. Aqui a beleza não é óbvia, ela está nos detalhes, na descoberta, na mistura, na interação com a natureza. Pensando nisso desenvolvemos um projeto  em que a volumetria valoriza cada unidade individualmente, e misturamos materiais naturais com metal em tom escuro. Este conjunto de fachadas aliado a forma de empregar os materiais trouxe um aspecto de vila moderna.",
          "datasheet": [
            "Projeto de Arquitetura: Sergio Gattas Arquitetura",
            "Fachada, Interiores e Personalização: Escala Arquitetura",
            "Projeto de paisagismo: Arteiro",
          ]
        }
      },
      {
        "sellpercent": 'LANÇAMENTO',
        "title": "Villa",
        "image": "./Lancamentos/fachada-villa.jpg",
        "location": "Leblon",
        "tags": [
          "Última Unidade",
          "64m²",
          "Up Garden, com opção Double Suites ou Sala/2 quartos",
          "Raridade: localização no Quadrilátero do Charme em Ipanema"
        ],
        "url": "https://villamozak.com.br/invista/"
      },
      {
        "sellpercent": 100,
        "title": "Acqua",
        "image": "./Lancamentos/fachada-acqua.jpg",
        "location": "Ipanema",
        "tags": [
          "Última Unidade",
          "64m²",
          "Up Garden, com opção Double Suites ou Sala/2 quartos",
          "Raridade: localização no Quadrilátero do Charme em Ipanema"
        ],
        "url": "https://acquamozak.com.br/"
      },
      {
        "sellpercent": 100,
        "slug": "areia",
        "image": "areia.png",
        "title": "Areia",
        "search": "Leblon|4 quartos|De 100m2 a 200m2 - Era, Azuis, Atobá",
        "location": "Leblon",
        "size": "De 200 a 300m2",
        "rooms": "4 quartos",
        "tags": [
          "Quadríssima da praia",
          "4 e 5 suítes",
          "267m² e 340m²",
          "Obras em andamento"
        ],
        "project": {
          "id": 18,
          "hero": "areia_hero.png",
          "title": "Areia",
          "video": "https://www.youtube.com/embed/20lcT12eBSk",
          "video_image": "",
          "datasheet_image": "areia_datasheet.png",
          "arch_image": "areia_arch.png",
          "subtitle": "Na quadríssima da praia do Leblon",
          "gallery": [
            "areia_gallery_1.png",
            "areia_gallery_2.png",
            "areia_gallery_3.png",
            "areia_gallery_4.png"
          ],
          "tags": [
            "Obras em andamento",
            "Unidades up garden e tipo",
            "Lazer completo",
            "4 ou 5 suítes",
            "267m² e 340m²"
          ],
          "address": "Rua Bartolomeu Mitre, 119, Leblon",
          "about": "Com vista para o mar do Leblon, sua calmaria, elegância e cenário são inspirações para os mais lindos versos e dias. O Areia combina exclusividade e sofisticação em um dos melhores pontos da zona sul do Rio.",
          "aboutProject": "O Areia é um projeto integrado com o entorno, onde o barulho do mar soa como poesia, a brisa abraça e o charme inspira o viver cheio de afeto. Visando a valorização do bairro em que se localiza, o empreendimento traz conceitos de urbanismo, beleza, segurança e bem-estar, com uma construção nova e uso das mais modernas tecnologias.",
          "arch_title": "FACHADA E INTERIORES POR DAVID BASTOS",
          "arch_text": "“Situado no Rio de Janeiro, mais precisamente no Leblon, bairro onde existe muita vida, o empreendimento está em local privilegiado, na quadra da praia, próximo a bares e restaurantes. E por conta disso, foi idealizado para ser uma moradia integrada à natureza, com tons e texturas naturais, como a areia, que acabou se tornando o nome do edifício. O uso de materiais naturais e grandes aberturas de vidro foram as premissas utilizadas na concepção da fachada, que não possui grande variedade de elementos ou cores, permanecendo mais minimalista, mas sem perder a elegância. Nas áreas comuns e nos apartamentos não foi diferente. Trouxemos o mesmo conceito para todo o empreendimento, enfatizando as tonalidades neutras, escolha de materiais naturais e o conceito de biofilia, que nada mais é do que dar valor às coisas vivas, promovendo bem-estar e conforto emocional.”",
          "datasheet": [
            "Apenas 2 unidades por andar com 4 e 5 suítes",
            "Unidades de 267m² e 340m²",
            "Autor do projeto: Inácio Obadia",
            "Personalização e customização",
            "Área de lazer completa com piscina climatizada, hidromassagem aquecida, academia, sauna a vapor e duchas."
          ]
        }
      },
      {
        "sellpercent": 100,
        "slug": "atoba",
        "image": "atoba.png",
        "title": "Atobá",
        "search": "Leblon|3 quartos|De 100m2 a 200m2 - Era, Azuis, Atobá",
        "location": "Leblon",
        "size": "De 100m2 a 200m2",
        "rooms": "3 quartos",
        "tags": [
          "Última cobertura duplex do Leblon",
          "3 quartos",
          "168m²",
          "Obras em andamento"
        ],
        "project": {
          "id": 33,
          "hero": "atoba_hero.png",
          "title": "Atobá",
          "video": "",
          "video_image": "atoba_video.png",
          "datasheet_image": "atoba_datasheet.png",
          "arch_image": "atoba_arch.png",
          "subtitle": "Última cobertura duplex do Leblon",
          "gallery": [
            "atoba_gallery_1.png",
            "atoba_gallery_2.png",
            "atoba_gallery_3.png",
            "atoba_gallery_4.png",
            "atoba_gallery_5.jpg",
            "atoba_gallery_6.jpg",
            "atoba_gallery_7.jpg",
            "atoba_gallery_8.jpg",
            "atoba_gallery_9.jpg",
            "atoba_gallery_10.jpg",
            "atoba_gallery_11.jpg",
          ],
          "tags": [
            "Entrega em 2024",
            "3 quartos",
            "168m²",
            "Área privativa exclusiva"
          ],
          "address": "Rua João Lira, 101",
          "about": "O Atobá fica localizado na Rua João Lira, 101. Um presente para o carioca que adora a natureza e de estar cercado de cultura, lazer, comércio e praticidade. Próximo de algumas das principais vias de acesso da cidade e do metrô Antero de Quental, morar no Atobá significa ter um dia a dia mais rápido, simples e sofisticado. É a liberdade de poder viver em um dos melhores bairros do Rio e saber que chegou no lugar que você sempre quis: o seu lugar.",
          "aboutProject": "Com uma equipe de profissionais especialistas, foi possível criar e executar este projeto em todas as suas etapas nos mínimos detalhes.",
          "arch_title": "PROJETO ARTÍSTICO E DE FACHADA DANEL SENISE",
          "arch_text": "Nasceu em 1955 no Rio de Janeiro. Em 1980, se formou em engenharia civil pela Universidade Federal do Rio de Janeiro, tendo ingressado na Escola de Artes Visuais do Parque Lage no ano seguinte, onde participou de cursos livres até 1983. Foi professor na mesma escola de 1985 a 1996. O artista trabalha com a construção e desconstrução de planos e perspectivas, se conectando muito com o processo arquitetônico. Desde os anos 80 vem participando de mostras coletivas. Atualmente, Daniel Senise vive e trabalha no Rio de Janeiro. As obras de Senise serviram de inspiração para o projeto arquitetônico da Cité Arquitetura, que convidou o artista plástico para desenvolver o painel que compõe a fachada do empreendimento.",
          "datasheet": [
            "Cobertura duplex de 168m²",
            "3 quartos",
            "Área privativa exclusiva",
            "Projeto arquitetônico Cité Arquitetura",
            "Design de interiores, áreas comuns e personalização: Bianca da Hora"
          ]
        }
      },
      {
        "sellpercent": 100,
        "slug": "azuis",
        "image": "azuis.png",
        "title": "Azuis",
        "search": "Lagoa|3 quartos|4 quartos|De 100m2 a 200m2 - Era, Azuis, Atobá|De 200 a 300m2 - Areia, Serena, Azuis",
        "location": "Lagoa",
        "size": "De 120 a 150m²",
        "rooms": "3 quartos",
        "tags": [
          "Última unidade em Ipanema com vista para a Lagoa",
          "3 quartos",
          "138m²",
          "Previsão de entrega: 2026"
        ],
        "project": {
          "id": 19,
          "hero": "azuis_hero.png",
          "title": "Azuis",
          "subtitle": "Última unidade em Ipanema com vista para a Lagoa",
          "video": "https://www.youtube.com/embed/RCeMP8sPMN0",
          "video_image": "",
          "datasheet_image": "azuis_datasheet.png",
          "arch_image": "azuis_arch.png",
          "gallery": [
            "azuis_gallery_1.png",
            "azuis_gallery_2.png",
            "azuis_gallery_3.png",
            "azuis_gallery_4.png",
            "azuis_gallery_5.png",
            "azuis_gallery_6.png",
            "azuis_gallery_7.png",
            "azuis_gallery_8.png",
            "azuis_gallery_9.png",
            "azuis_gallery_10.png",
            "azuis_gallery_11.png",
            "azuis_gallery_12.png",
            "azuis_gallery_13.png",
            "azuis_gallery_14.png",
            "azuis_gallery_15.png",
          ],
          "tags": [
            "3 quartos",
            "138m²",
            "Previsão de entrega: 2026"
          ],
          "address": "Avenida Epitácio Pessoa, 1498. Esquina com a Joana Angélica.",
          "about": "O clima calmo e contemporâneo da Lagoa e, ao mesmo tempo, a 10 minutos da praia de Ipanema e de toda a efervescência do bairro. Azuis fica na Epitácio Pessoa, 1498, esquina com a Joana Angélica, oferecendo a possibilidade de explorar o que há de melhor nos dois bairros, que se complementam entre paz e vivacidade.",
          "aboutProject": "Como o próprio nome já diz, Azuis foi pensando para integrar os tons diversos que trazem essa cor: o panorama do céu, pincelado pelas grandes pedras, o mar e as águas da Lagoa. Tudo isso refletido em um projeto elegante que valoriza uma das regiões mais icônicas do Rio. O empreendimento alia beleza, segurança e bem-estar a uma construção nova e uso das mais modernas tecnologias.",
          "arch_title": "Ouriço Arquitetura",
          "arch_text": "Elaborado com toda minúcia e atenção aos mínimos detalhes pela Ouriço Arquitetura, o projeto de fachada e interiores do Azuis é um elogio à Lagoa. Suas formas elegantes se conectam com a paisagem, enaltecendo sua beleza natural. O trabalho da Ouriço é definido por sua capacidade de entender e respeitar a personalidade dos clientes e ainda conseguir surpreendê-los.",
          "datasheet": [
            "Vista eterna para a Lagoa",
            "3 ou 4 suítes",
            "Projeto de arquitetura Flávio Bassan",
            "Personalização e customização ajustáveis"
          ]
        }
      },
      {
        "sellpercent": 100,
        "slug": "marias",
        "image": "Marias/MariasNewThumb.webp",
        "title": "Marias",
        "search": "Ipanema|Estúdio|De 40 a 60m2 - Marias, Era, Serena",
        "location": "Ipanema",
        "size": "De 137 a 297m²",
        "rooms": "Double Suites|Cobertura|Triplex",
        "tags": [
          "Estúdio",
          "Vista lagoa",
          "45m²",
          "Lazer completo",
          "Obras iniciadas"
        ],
        "project": {
          "id": 20,
          "hero": "Marias/MariasNewThumb.webp",
          "title": "Marias",
          "video": "",
          "video_image": "marias_video.png",
          "subtitle": "Estúdios lineares em Ipanema",
          "datasheet_image": "marias_datasheet.png",
          "arch_image": "marias_arch.png",
          "gallery": [
            "marias_gallery_1.png",
            "marias_gallery_2.png",
            "marias_gallery_3.png",
            // "marias_gallery_4.png",
            "marias_gallery_5.jpg",
            "marias_gallery_6.jpg",
            "marias_gallery_7.jpg",
            "marias_gallery_8.jpg",
            "marias_gallery_9.jpg",
            "marias_gallery_10.jpg",
            "marias_gallery_11.jpg",
            "marias_gallery_12.jpg",
            "marias_gallery_13.jpg",
            // "marias_gallery_14.jpg",
            // "marias_gallery_15.jpg",
            "marias_gallery_16.jpg",
          ],
          "tags": [
            "Estúdio",
            "Vista lagoa",
            "45m²",
            "Lazer completo",
            "Obras iniciadas"
          ],
          "address": "Rua Visconde de Pirajá, 529",
          "about": "Em meio às escaldantes ruas e um ângulo não muito comum, o Marias se localiza entre as belezas que titulam a capital como cidade maravilhosa. De um lado a vista para a encantadora lagoa Rodrigo de Freitas e do outro a praia de Ipanema, o que torna o dia a dia por lá inigualável. Sua rua é a famosa Visconde de Pirajá, uma das principais do bairro e onde encontra-se de tudo, levando a você mil possibilidades para dar vida ao novo, cercado de estilo.",
          "aboutProject": "Marias leva você a descobrir que morar por lá é encanto puro e que para começar um novo ciclo com mil possibilidades basta apostar, viver e ser feliz. Sua beleza é traduzida em estúdios que possuem o conjunto essencial para você: aconchego, praticidade, vivacidade elegância.",
          "arch_title": "Pedrosa & Pedrosa",
          "arch_text": "Liderado por Cynthia e Raphael Pedrosa, o escritório conta com uma equipe de profissionais capacitados a executar projetos exclusivos, de pequeno a grande porte, e com alto grau de complexidade. A linha de design característica do escritório propõe uma conversa entre o clássico, com referências charmosas, e o moderno, com características leves e amplas. O resultado desta mistura são projetos que refletem a harmonia e o equilíbrio.",
          "datasheet": [
            "Autor do projeto: Inácio Obadia",
            "Paisagismo: Embyá Arquitetura",
            "Bicicletário",
            "Lazer completo com piscina, espaço gourmet, salão gourmet, sauna, espaço fitness e lavanderia compartilhada"
          ]
        }
      },
      // {
      //   "sellpercent": 67,
      //   "slug": "artis",
      //   "image": "Artis/thumb.png",
      //   "title": "Artís",
      //   "search": "Ipanema|2 quartos|De 70 a 100m2 - Era, Helô, Artis",
      //   "location": "Ipanema",
      //   "size": "De 70 a 100m2",
      //   "rooms": "2 quartos",
      //   "tags": [
      //     "Único 2 quartos de Ipanema",
      //     "Garden, tipo e cobertura",
      //     "71m² a 144m²"
      //   ],
      //   "project": {
      //     "id": 67,
      //     "hero": "Artis/project_thumb.jpg",
      //     "title": "Artís",
      //     "subtitle": "Único 2 quartos de Ipanema",
      //     "video": "https://www.youtube.com/embed/RhNSKzqU8Eo",
      //     "datasheet_image": "artis_datasheet.png",
      //     "arch_image": "artis_arch.png",
      //     "gallery": [

      //       "artis_gallery_1.png",
      //       "artis_gallery_2.png",
      //       "artis_gallery_3.png",
      //       "artis_gallery_4.png",
      //       "artis_gallery_5.jpg",
      //       "artis_gallery_6.jpg",
      //       "artis_gallery_7.jpg",
      //       "artis_gallery_8.jpg",
      //       "artis_gallery_9.jpg",
      //       "artis_gallery_10.jpg",
      //       "artis_gallery_11.jpg",
      //       "artis_gallery_12.jpg",
      //     ],
      //     "tags": [
      //       "Único 2 quartos de Ipanema",
      //       "Garden, tipo e cobertura",
      //       "71m² a 144m²"
      //     ],
      //     "address": "Rua Nascimento Silva, 338, Ipanema",
      //     "about": "A 5 minutos a pé tanto da praia, como da Lagoa, em uma das ruas mais tranquilas de Ipanema e, ao mesmo tempo, a poucos passos do comércio local.",
      //     "aboutProject": "A arquitetura é um convite a continuar a experiência de se viver no bairro. Contornos e movimentos inspiram espaços. Cores e aquarelas traduzem a leveza da essência carioca. Tudo em Artís fala: viver é mais que morar.",
      //     "arch_title": "Fachada e interiores Hana Lerner",
      //     "arch_text": "A arquitetura é um convite a continuar a experiência de se viver no bairro. Contornos e movimentos inspiram espaços. Cores e aquarelas traduzem a leveza da essência carioca. Tudo em Artís fala: viver é mais que morar.",
      //     "datasheet": [
      //       "Garden | Tipo | cobertura",
      //       "71m² a 144m²",
      //       "Espaço Coworking",
      //       "Bicicletário",
      //       "Intervenção artística: Linda Lerner",
      //       "Luminotécnica: Paula Chiaradia & Ana Carolina Gayoso",
      //       "Paisagismo: Gica Mesiara",
      //       "Autor do projeto: Flávio Bassan"
      //     ]
      //   }
      // },
      {
        "sellpercent": 100,
        "slug": "helo",
        "image": "helo.png",
        "title": "Helô",
        "location": "Ipanema",
        "size": "De 70 a 100m2",
        "rooms": "2 quartos",
        "tags": [
          "2 quartos",
          "Unidade garden de 79m²",
          "Última unidade"
        ],
        "project": {
          "id": 27,
          "hero": "helo_hero.png",
          "title": "Helô",
          "video": "https://www.youtube.com/embed/WsTpo5m7Low",
          "video_image": "",
          "subtitle": "O estilo e a leveza De Ipanema vão morar neste endereço.",
          "datasheet_image": "helo_datasheet.png",
          "arch_image": "helo_arch.png",
          "gallery": [
            "helo_gallery_1.png",
            "helo_gallery_2.png",
            "helo_gallery_3.png",
            "helo_gallery_4.png",
            "helo_gallery_5.jpg",
            "helo_gallery_6.jpg",
            "helo_gallery_7.jpg",
            "helo_gallery_8.jpg",
            "helo_gallery_9.jpg",
          ],
          "tags": [
            "Última unidade garden de 79m²",
            "2 quartos na melhor rua de Ipanema",
            "Apenas 6 unidades"
          ],
          "address": "Rua Redentor, 68, Ipanema",
          "about": "Venha sentir a vida fluir nos espaços únicos de um lugar que foi criado com arte e sensibilidade para receber você, com um projeto à altura de Ipanema, que resulta de uma cuidadosa curadoria da Mozak para selecionar e reunir artista que, assim como nós, amam o Rio.",
          "aboutProject": "Entre tantas descobertas, existe este lugar que recebeu um nome feminino, elegante e atraente. Um nome que nasceu da inspiração de tudo o que existe ao redor. Em meio ao seu caminhar, você vai encontrar Helô. E, com certeza, você vai se encontrar aqui.",
          "arch_title": "Fachada e interiores Garimpório Arquitetura",
          "arch_text": "“Com a influência da arquitetura francesa e da estética Art Déco, que tanto fazem parte da história do nosso Rio de Janeiro, trazemos pro bairro de Ipanema um encontro entre o tradicional e o contemporâneo. Nesse projeto, as retas e curvas resultam em aconchego, movimento e elegância”.",
          "datasheet": [
            "Garden de 79m²",
            "Cowoking e lavanderia compartilhada",
            "Bicicletário",
            "Intervenção artística: Ana Biolchini",
            "Luminotécnica: Chiaradia + Gayoso",
            "Paisagismo: Wabi-Sabi Ateliê",
            "Autor do projeto: Flávio Bassan"
          ]
        }
      },
      {
        "sellpercent": 'OPORTUNIDADE',
        "slug": "botafogo",
        "image": "Botafogo/Cobertura.webp",
        "title": "Era Botafogo",
        "search": "Botafogo|Sala/quarto|3 quartos|De 70 a 100m2 - Era, Helô, Artis|De 100m2 a 200m2 - Era, Azuis, Atobá",
        "location": "Botafogo",
        "size": "De 90 a 120m²|De 120 a 150m²",
        "rooms": "2 quartos|Cobertura",
        "tags": [
          "Última unidade cobertura de Botafogo",
          "2 quartos com rooftop e piscina",
          "Lazer completo",
          "Previsão de entrega: 2025"
        ],
        "project": {
          "id": 17,
          "hero": "Botafogo/Cobertura.webp",
          "title": "Era Botafogo",
          "subtitle": "Um projeto que mescla cultura, história e dinamismo, assim como Botafogo.",
          "video": "https://www.youtube.com/embed/EU2-WBcgi5Q",
          "video_image": "",
          "datasheet_image": "botafogo_datasheet.png",
          "arch_image": "botafogo_arch.png",
          "gallery": [
            "./Botafogo/Academia l Mozak l Dona Mariana l Final HD.jpg",
            "./Botafogo/Fachada.webp",
            "./Botafogo/Festas l Mozak l Dona Mariana l Final HD.jpg",
            "./Botafogo/Lavanderia l Mozak l Dona Mariana l Final HD.jpg",
            "./Botafogo/Portaria.webp",
            "./Botafogo/Sala_cobertura.webp",
            "./Botafogo/Terraco_cobertura.webp"
          ],
          "tags": [
            "Última unidade cobertura de Botafogo",
            "2 quartos com rooftop e piscina",
            "Lazer completo",
            "Previsão de entrega: 2025"
          ],
          "address": "Rua Dona Mariana, 56, Botafogo",
          "about": "Em uma região com vida pulsante, a Rua Dona Mariana se destaca pela sua tranquilidade. Arborizada, agradável, é um refúgio em meio ao comércio e restaurantes. É nesse cenário, e entre as duas principais vias do bairro - Ruas São Clemente e Voluntários da Pátria - que ERA está localizado.",
          "aboutProject": "Fruto de um projeto desafiador, onde a memória convive com uma nova construção, surge o novo empreendimento da Mozak. Na frente do terreno, um casarão histórico, que faz parte da memória do bairro. Atrás, um novo edifício, com traços contemporâneos e ritmados.",
          "arch_title": "Projeto de fachada PKB (bloco 2)",
          "arch_text": "“Pensamos a fachada do ERA considerando o contexto e a localização onde essa edificação está inserida: Botafogo, um bairro histórico e, ao mesmo tempo, plural, que agrega diversos estilos, comércios, rodeado de pontos turísticos e bastante movimentado. No terreno, o Casarão de estilo eclético exprime elegância e tradição. Nosso ponto de partida foi manter o legado e a importância da arquitetura existente, propondo a inserção de uma fachada contextual. O novo prédio busca dialogar com o cenário ao seu redor, condicionando-se ao momento atual, com personalidade e, ao mesmo tempo, sem sobrepor ou mesmo reproduzir a arquitetura existente. Através de ritmo e proporção, procuramos exprimir uma arquitetura neutra, sofisticada e singular”",
          "datasheet": [
            "2 pavimentos: 1 casarão e 1 edifício contemporâneo",
            "Paisagismo: Embyá",
            "Projeto de interiores: Bianca da Hora",
            "Projeto arquitetônico: Sérgio Gattás",
            "Lavanderia compartilhada no térreo",
            "Rooftop com piscina, academia e salão gourmet"
          ]
        }
      },
      {
        "sellpercent": 100,
        "slug": "serena",
        "image": "serena.png",
        "title": "Serena",
        "search": "Jardim Botânico|Sala/quarto|2 quartos|3 quartos|De 200 a 300m2 - Areia, Serena, Azuis|De 40 a 60m2 - Marias, Era, Serena",
        "location": "Jardim Botânico",
        "size": "De 200 a 300m2|De 40 a 60m2",
        "rooms": "Sala/quarto|2 quartos|3 quartos",
        "tags": [
          "Vista para o Cristo Redentor",
          "Sala/quarto e Sala/2 quartos com garagem",
          "Obras em andamento",
          "Previsão de entrega: 2025"
        ],
        "project": {
          "id": 16,
          "hero": "serena_hero.png",
          "title": "Serena",
          "video": "",
          "video_image": "serena_video.png",
          "subtitle": "VISTA PARA O CRISTO REDENTOR.",
          "datasheet_image": "serena_datasheet.png",
          "arch_image": "serena_arch.png",
          "gallery": [
            "serena_gallery_1.png",
            "serena_gallery_2.png",
            "serena_gallery_3.png",
            "serena_gallery_4.png",
            "serena_gallery_5.jpg",
            "serena_gallery_6.jpg",
            "serena_gallery_7.jpg",
            "serena_gallery_8.jpg",
            "serena_gallery_9.jpg",
            "serena_gallery_10.jpg",
            "serena_gallery_11.jpg",
            "serena_gallery_12.jpg",
            "serena_gallery_13.jpg",
          ],
          "tags": [
            "Vista para o Cristo Redentor",
            "Sala/quarto e Sala/2 quartos com garagem",
            "Obras em andamento",
            "Previsão de entrega: 2025"
          ],
          "address": "Rua Conde Afonso Celso, 58, Jardim Botânico",
          "about": "Estar no Jardim Botânico é se conectar com a natureza. É respirar o ar puro e ver árvores e plantas que pintam as ruas com tons de verde. Caminhar por aqui é desfrutar da tranquilidade e se inspirar com a beleza. É impossível não se apaixonar.",
          "aboutProject": "Conheça Serena e descubra que a tranquilidade do bairro mais charmoso da Zona Sul pode ser traduzida para linhas arquitetônicas. Um residencial de design exclusivo e acolhedor, projetado por profissionais criativos e assinado pela Mozak",
          "arch_title": "Fachada e interiores Natália Lemos",
          "arch_text": "A arquiteta à frente do escritório, Natália Lemos, traz mais de 15 anos de experiência na área. Oferece um serviço completo e personalizado, participando de todas as etapas do projeto, desde o desenvolvimento conceitual até o acompanhamento das obras. O escritório é formado por uma equipe jovem e criativa que tenta sempre encontrar novas soluções para criar projetos diferenciados e exclusivos.",
          "datasheet": [
            "Projeto de paisagismo: Wabi-Sabi Ateliê",
            "Autor do projeto: Flávio Bassan",
            "Bicicletário",
            "14 unidades"
          ]
        }
      },

      // Flora
      {
        "sellpercent": "OPORTUNIDADE",
        "slug": "flora",
        "image": "FloranewCapa.webp",
        "title": "Flora",
        "search": "Jardim Botânico|Sala/quarto|Flora",
        "location": "Jardim Botânico",
        "size": "De 30 a 60m²",
        "rooms": "Estúdio|Cobertura",
        "tags": [
          "Estúdio",
          "41m²",
          "Vaga de garagem opcional",
          "Previsão de entrega: 2024"
        ],
        "project": {
          "id": 16,
          "hero": "FloranewCapa.webp",
          "title": "Flora",
          "video": "",
          "video_image": "flora_video.png",
          "subtitle": "",
          "datasheet_image": "flora_datasheet.jpg",
          "arch_image": "RAFarquitetura.jpg",
          "gallery": [
            "flora_gallery_1.jpg",
            "flora_gallery_2.jpg",
            "flora_gallery_3.jpg",
            "flora_gallery_5.jpg",
            "flora_gallery_4.jpg",
            "flora_gallery_6.jpg",
            "flora_gallery_7.jpg"
          ],
          "tags": [
            "Estúdio",
            "41m²",
            "Vaga de garagem opcional",
            "Obras em andamento",
            "Previsão de entrega: 2024"
          ],
          "address": "Rua Jardim Botânico, 548, Jardim Botânico",
          "about": "Viver no Jardim Botânico é se conectar com a essência natural da cidade, caminhando em meio ao ar puro e tranquilidade. Caminhar por aqui é se inspirar com a beleza vida em movimento, ouvir o canto de pássaros pela manhã e recordar que faz parte da nossa natureza ser carioca.",
          "aboutProject": "Conheça o Flora e descubra a sinergia entre uma arquitetura viva e o respiro urbano no bairro mais encantador da Zona Sul. Um residencial de design exclusivo e inspirado na natureza, projetado por profissionais renomados e assinado pela Mozak.",
          "arch_title": "Projeto Executivo RAF Arquitetura",
          "arch_text": 'Fundada em 1989, RAF Arquitetura traduz o Flora como um empreendimento em homenagem à alma verde e solar do Jardim Botânico: "Os detalhes da arquitetura foram pensados para Biofilia, pela paixão à natureza, pela satisfação à vida. Propusemos um design contemporâneo, com formas e materiais que permeiam o nosso imaginário, influenciando de forma positiva o bem estar, e as sensações humanas."',
          "datasheet": [
            "Projeto de paisagismo: Embyá",
            "Fachada, Interiores, Personalização e Luminotécnica: Bianca da Hora",
            "Projeto de Arquitetura: Inácio Obadia",
            "Bicicletário",
            "Soluções sustentáveis"
          ]
        }
      },

      {
        "sellpercent": 100,
        "slug": "afranio",
        "image": "afranio.png",
        "title": "Afrânio",
        "location": "Leblon",
        "size": "De 120 a 150m²",
        "rooms": "Sala Comercial",
        "tags": [
          "Última sala comercial",
          "135m²",
          "Design assinado",
          "Garagem no subsolo"
        ],
        "project": {
          "id": 30,
          "hero": "afranio_hero.png",
          "title": "Afrânio",
          "video": "https://www.youtube.com/embed/q8tcDUROX00",
          "video_image": "",
          "subtitle": "Um corporativo que traduz a essência do Rio em arquitetura e arte",
          "datasheet_image": "afranio_datasheet.png",
          "arch_image": "afranio_arch.png",
          "gallery": [
            "afranio_gallery_1.png",
            "afranio_gallery_2.png",
            "afranio_gallery_3.png",
            "afranio_gallery_4.png",
            "afranio_gallery_5.jpg",
            "afranio_gallery_6.jpg",
            "afranio_gallery_7.jpg",
            "afranio_gallery_8.jpg",
            "afranio_gallery_9.jpg",
            "afranio_gallery_10.jpg",
            "afranio_gallery_11.jpg",
          ],
          "tags": [
            "Última sala comercial",
            "83m² a 178 m²",
            "Design assinado",
            "Garagem no subsolo"
          ],
          "address": "Av. Afrânio de Melo Franco, 135, Leblon",
          "about": "No Afrânio Mozak, a arte assume seu protagonismo em uma arquitetura icônica. Um Corporativo Design que apresenta texturas e cores para transformar o trabalho e a vida de quem passa por uma das áreas mais nobres da cidade.",
          "aboutProject": "Como uma homenagem à cidade, Afrânio materializa o que há de melhor na identidade carioca através de linhas que trazem a sofisticação em detalhes leves, que acolhem com simplicidade. Seu design revela sentidos através do equilíbrio entre materiais, trazendo elegância na medida certa para um Corporativo Mozak.",
          "arch_title": "o Fachada e interiores Ivan Rezende",
          "arch_text": "Da sólida materialidade do embasamento, fazer surgir um volume que se duplica em corpo e pele, evidenciando transparências e permeabilidades. Aproveitar, valorizar, recuperar e adequar a Arquitetura existente às demandas dos novos tempos é uma ação sustentável. É, também, revigorar a cidade através de seus objetos Arquitetônicos.",
          "datasheet": [
            "Projeto de paisagismo: Embyá",
            "Autor do projeto: A+ Arquitetura",
            "Luminotécnica: Christina Draeger",
            "31 salas comerciais de 83m² a 178 m²"
          ]
        }
      },
      {
        "sellpercent": 'LANÇAMENTO',
        "title": "Máris",
        "image": "./Lancamentos/fachada-maris.jpg",
        "location": "Leblon",
        "tags": [
          "Salas de 19m² a 66m²",
          "Ideal para escritórios, consultórios e ateliês",
          "Localização estratégica, apenas 5 minutos do metrô"
        ],
        "url": "https://marismozak.com.br/consultorio/"
      },
      {
        "sellpercent": 100,
        "slug": "largodopiva",
        "image": "Essencia/LargodoPivaCapa.webp",
        "title": "Largo do Piva",
        "location": "Leblon",
        "size": "De 90 a 120m²|De 180 a 600m²",
        "rooms": "Loja",
        "tags": [
          'Último espaço corporativo do Leblon para locação, com 569m²',
          "Ideal para sedes corporativas, clínicas, laboratórios e academias",
          "Empreendimento pronto",
        ],
        "project": {
          "id": 5,
          "hero": "Essencia/LargoPivaHero.webp",
          "title": "Largo do Piva",
          "subtitle": "Um lugar para descobrir a arte de viver o Rio",
          "tags": [
            'Último espaço corporativo do Leblon para locação, com 569m²',
            "Ideal para sedes corporativas, clínicas, laboratórios e academias",
            "Empreendimento pronto",
          ],
          "address": "Largo do Piva, Rua Juquiá, 61, Leblon",
          "about": "A ideia é desenvolver um novo polo de arte, consumo e cultura no Leblon. As lojas no térreo trarão uma nova dinâmica para a região. Além das intervenções artísticas e criativas, que vão agitar a vida local e mudar a atmosfera do entorno, as lojas e suas atividades vão atrair os cariocas amantes da arte e do encontro.",
          "video": "..Essencia/Largo do Piva_Mozak_reduzido.mp4",
          "video_image": "",
          "aboutProject": "Com suas intervenções artísticas e conceituais, esse novo espaço traz um clima de efervescência criativa que será o ponto de encontro da arte, da urbanidade e da cultura cariocas. Um lugar para viver o dia e a noite, encontrar os amigos e poder contar com toda a estrutura do bairro a poucos passos de você. Isso é a arte de viver com a leveza e o estilo típicos do Rio.",
          "gallery": [
            "Essencia/img1essencia.jpg",
            "Essencia/img2essencia.jpg",
            "Essencia/essencia_img5.jpg",
            "Essencia/img4essencia.jpg",
            "Essencia/img5essencia.jpeg",

          ],
          "arch_title": "o Fachada e interiores André Piva",
          "arch_text": "Mais que um projeto, um legado de beleza. Essência é um dos últimos projetos do arquiteto André Piva, que nos deixa essa obra como memória da sua mente criativa.Queremos que todos que olharem para este conjunto arquitetônico possam sentir nele a presença e a arte de André.",
          "arch_image": "essencia_arch.png",
          "datasheet_image": "essencia_datasheet.png",
          "datasheet": [
            "Terreno de 1.636 com total de 2 blocos",
            "Calçada de até 11 metros de largura que abriga intervenções artísticas e estéticas",
            "Itens de segurança e tecnologia que trazem maior proteção",
            "Itens sustentáveis que colaboram para a qualidade de vida e geram a redução dos custos condominiais",
            "Curadoria que reúne Vanessa Borges, Luiz D’orey, Marina Caverzan, Marina Rordrigues, Coletivo Muda e Ateliê Wabi-Sabi",
            "Projeto de paisagismo: Rodrigo Oliveira",
            "Luminotécnica: Christina Draeger",
            "Iluminação: Maneco Quinderé"
          ]
        }
      },
      {
        "sellpercent": 84,
        "slug": "gavea",
        "image": "gavea_hero.png",
        "title": "Parque Sustentável Da Gávea",
        "tags": [
          "Estúdios, 2 e 3 quartos",
          "Mais de 8 mil m² de área de lazer",
          "Obras avançadas",
          "Previsão de entrega: 2025"
        ],
        "location": "Gávea",
        "size": "De 10 a 30m²|De 30 a 60m²|De 60 a 90m²|De 90 a 120m²|De 150 a 180m²",
        "rooms": "Estúdio|2 quartos|3 quartos",
        "project": {
          "id": 21,
          "hero": "gavea_hero.png",
          "title": "PARQUE SUSTENTÁVEL DA GÁVEA",
          "subtitle": "Surpreendente hoje. Inigualável amanhã. Imperdível em todos os sentidos",
          "tags": [
            "Estúdios, 2 e 3 quartos",
            "Mais de 8 mil m² de área de lazer",
            "Obras avançadas",
            "Previsão de entrega: 2025"
          ],
          "address": "Rua Marquês de São Vicente, 104",
          "about": "Está nascendo uma outra Gávea dentro da Gávea. Que vem para resgatar o outro você que existe dentro de você. Aquele que está em busca de uma vida mais equilibrada e conectada com a natureza, mas que não abre mão da agitação social e cultural do seu bairro. A Gávea é exatamente como você é: está sempre se transformando e valorizando a sua vivência, a sua história. Esta Gávea que mora dentro de você vai ganhar de volta uma área com mais de 25 mil m² que será cuidadosamente integrada à Mata Atlântica, no novo empreendimento da Mozak, onde os espaços se completam e se comunicam, em um conjunto que une arte, leveza e estilo de vida. Quem disse que é impossível encontrar um lugar onde você pode ser o que você realmente é? Pode ter certeza: você encontrou.",
          "video": "https://www.youtube.com/embed/-K7cqn2dvGc",
          "video_image": "",
          "aboutProject": "Em um terreno com mais de 25 mil m², o parque sustentável da gávea terá um espaço aberto ao público com acesso a trilhas, espaços para contemplação e contato com a natureza. Para isso serão preservados 17 mil m² de área de floresta, onde haverá ainda um mirante para oferecer aos visitantes áreas para piquenique, contemplação e lazer",
          "gallery": [
            "gavea_gallery_1.png",
            "gavea_gallery_2.png",
            "gavea_gallery_3.png",
            "gavea_gallery_4.png",
            "gavea_gallery_5.jpg",
            "gavea_gallery_6.jpg",
            "gavea_gallery_7.jpg",
            "gavea_gallery_8.jpg",
            "gavea_gallery_9.jpg",
            "gavea_gallery_10.jpg",
            "gavea_gallery_11.jpg",
            "gavea_gallery_12.jpg",
            "gavea_gallery_13.jpg",
            "gavea_gallery_14.jpg",
            "gavea_gallery_15.jpg",
            "gavea_gallery_16.jpg",
            "gavea_gallery_17.jpg",
            "gavea_gallery_18.jpg",
            "gavea_gallery_9.jpg",
            "gavea_gallery_20.jpg",
            "gavea_gallery_21.jpg",
            "gavea_gallery_22.jpg",
            "gavea_gallery_23.jpg",
            "gavea_gallery_24.jpg",
            "gavea_gallery_25.jpg",
            "gavea_gallery_26.jpg",
          ],
          "arch_title": "",
          "arch_text": "Os projetos das fachadas e interiores foram desenvolvidos por jovens expoentes da arquitetura carioca, prevendo uma solução sustentável com construções de até 4 andares com apartamentos residenciais e lojas que vão trazer ainda mais vida e beleza para a gávea. Tudo foi pensado nos mínimos detalhes, de maneira que cada edificação seja única e exclusiva, transformando o local em uma verdadeira exposição de arte a céu aberto.",
          "arch_image": "gavea_arch.png",
          "datasheet_image": "gavea_datasheet.png",
          "datasheet": [
            "Autor do projeto: Flávio Bassan",
            "Projeto paisagístico: Escritório de paisagismo Burle Marx",
            "Vagas de garagem",
            "17 mil m² de floresta preservada",
            "Mais de 8 mil m² de área de lazer que incluem spa gramado, churrasqueira, piscina adulto e infantil, área de contemplação, playground, mirante e um clube exclusivo",
          ]
        }
      },
    ]
  }
  return data
}
export function getAllProjectSlugs() {
  const data = getData();
  const slugs = data.projects.map((item) => {
    return item.slug
  })
  return slugs
}
