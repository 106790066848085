import '../assets/styles/components/Footer.sass'
import React, { useState } from "react";
import InputMask from 'react-input-mask';

import Internit from '../assets/images/internit.png'

export default function Footer({ productId }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sending, setSending] = useState(false);

  function checkData(e) {

    if (name != "" && email != "" && phone != "") {
      sendData(name, email, phone);
      setSending(true);
    } else {
      alert('Preencha todos os campos antes de enviar');
    }

  }

  function getMediaFromURL() {
    var url = window.location.href;
    var allParams = url.split('?');

    if (allParams.length < 2) {
      return 'Direct'
    }

    var params = allParams[1].split('&');

    var source, utm_source, utm_medium, utm_campaign;

    if (params.find(element => element.includes('gclid')) != undefined) {
      // gclid = params.find(element => element.includes('utm_campaign')).split('=')[1];
      return 'Google Ads';
    }

    // if(params.find(element => element.includes('posicionamento')) != undefined){
    //   source = params.find(element => element.includes('posicionamento')).split('=')[1];
    //   return source
    // }

    if (params.find(element => element.includes('utm_source')) != undefined) {
      source = params.find(element => element.includes('utm_source')).split('=')[1];
      return source;
    }


    return 'Landing page';
  }

  function sendData(name, email, phone) {
    var midia = getMediaFromURL();

    const formData = new FormData();
    formData.append('nome', name);
    formData.append('email', email);
    formData.append('tel_celular', phone);
    formData.append('id_produto', productId);
    formData.append('midia', midia);

    fetch('https://mozak.hypnobox.com.br/email.receber.php', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        alert('Dados enviados com sucesso!');
        setName("");
        setEmail("");
        setPhone("");
        window.location.reload();
      })
  }

  return (
    <footer className="footer" id="footer">

      <div className="form">

        <h2>Gostou?</h2>
        <h3>Agende uma reunião com um dos nossos especialistas
        </h3>

        <input type="text" onChange={(e) => setName(e.target.value)} placeholder="Nome" />
        <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
        <InputMask type='text' mask="(99) 99999-9999" onChange={(e) => setPhone(e.target.value)} placeholder="Telefone" />

        <button
          onClick={checkData}
          disabled={sending}
          style={{ opacity: sending ? 0.5 : 1, cursor: sending ? 'not-allowed' : 'pointer' }}
          >
            {sending ? 'Enviando...' : 'Enviar'}
        </button>
      </div>

      <p className='creci'>CRECI RJ-008658/O</p>

      <a href='https://internit.com.br/' target='_blank' class="desenvolvido" rel="noreferrer">
        <span>Desenvolvido por</span>
        <img src={Internit} alt="Desenvolvido por Internit" />
      </a>

    </footer>
  );
}